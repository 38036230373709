.bubble {
    width: 80%;
    border-radius: 7px;
    margin: 4% 0;
    padding: 4%;
    background-color: var(--bg-clear);
    border: 2px var(--bg-clear) solid;
    box-shadow: 2px 2px 10px var(--weak-shadow);
    text-align: left;
}

.bubble-title {
    margin: 0 0 2% 1%;
    font-weight: 700;
}

.bubble-details {
    width: 98%;
    padding: 1% 2%;
    background-color: var(--theme-text);
    color: var(--shadow);
    border-radius: 7px;
    font-size: calc(.9vh + 1vmin);

}

.bubble-desc {
    margin: 1%;
    text-align: justify;
}

.bubble-skills-group {
    display: inline;
    width: 100%;
}

.bubble-skill {
    display: inline-block;
    text-align: center;
    background-color: var(--theme-text);
    color: var(--weak-shadow);
    padding: 1% 2%;
    border-radius: 7px;
    margin-block: 1%;
    margin-inline: 1%;
    transition: all .4s;
}

.bubble-skill:hover {
    background-color: var(--theme-lighter-text);
    color: var(--important-text);
    cursor:default;
}

.timeline-view {
    display: none;
}

.mobile-view {
    display: block;
    margin-top: 4%;
}



@media screen and (min-width: 1024px) {
    .bubble {
        margin: 0 0 0 2%;
    }

    .timeline-view {
        display: block;
    }

    .mobile-view {
        display: none;
    }
}

.link-container {
    margin-block: 1%;
    margin-inline: 1%;
    display: flex;
    justify-content: center;
}