.test {
    padding: 15vh 15vw;
    display: flex;
    flex-direction: column;
}

.btn {
    position: absolute;
    right: 10%;
    top: 35%;
    width: 35px;
    cursor: pointer;
}

.btn-mini {
    position: absolute;
    right: 7%;
    top: 3%;
    width: 35px;
    cursor: pointer;
}

.bar {
    display: block;
    width: 100%;
    box-shadow: 0 2px 10px 0 var(--weak-shadow);
    border-radius: 3px;
    height: 4px;
    background: var(--important-text);
    transition: all .3s;
    position: relative;
}

.bar + .bar {
    margin-top: 5px;
}

.active .bar:nth-child(1) {
    animation: ease .7s top forwards;
}

.not-active .bar:nth-child(1) {
    animation: ease .7s top-2 forwards;
}

.active .bar:nth-child(2) {
    animation: ease .7s scaled forwards;
}

.not-active .bar:nth-child(2) {
    animation: ease .7s scaled-2 forwards;
    background: black;
}

.active .bar:nth-child(3) {
    animation: ease .7s bottom forwards;
    background: var(--link);
}

.not-active .bar:nth-child(3) {
    animation: ease .7s bottom-2 forwards;
    background: var(--link);
}

@keyframes top {
    0% {
        top: 0;
        transform: rotate(0);
    }
    50% {
        top: 4px;
        transform: rotate(0);
    }
    100% {
        top: 8px;
        transform: rotate(45deg);
    }
}

@keyframes top-2 {
    0% {
        top: 10px;
        transform: rotate(45deg);
    }
    50% {
        top: 10px;
        transform: rotate(0deg);
    }
    100% {
        top: 0;
        transform: rotate(0deg);
    }
}

@keyframes bottom {
    0% {
        bottom: 0;
        transform: rotate(0);
    }
    50% {
        bottom: 10px;
        transform: rotate(0);
    }
    100% {
        bottom: 10px;
        transform: rotate(135deg);
    }
}

@keyframes bottom-2 {
    0% {
        bottom: 10px;
        transform: rotate(135deg);
    }
    50% {
        bottom: 10px;
        transform: rotate(0);
    }
    100% {
        bottom: 0;
        transform: rotate(0);
    }
}

@keyframes scaled {
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
    }

    @keyframes scaled-2 {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
