.profile {
    padding: 5vh 5vw;
    text-align: center;
}

.profile-desc {
    text-align: justify;
}

.profile-desc-height {
    height: 100%;
    width: 100%;
    margin-top: -5vh;
    margin-bottom: 5vh;
}

.picture {
    width: 42vh;
    border-radius: 700px;
    box-shadow: 2px 2px 10px var(--shadow);
    height: 42vh;
    object-fit: cover;
}

.profile-skills {
    background-color: var(--theme-text);
    color: var(--bg-clear);
    padding: 1.5%;
    border-radius: 7px;
    transition: all .4s;
    width: 75%;
    margin: 1vh 0 1vh 12.5%;
}

.profile-skills:hover {
    background-color: var(--theme-lighter-text);
    color: var(--important-text);
    font-size: calc(1.4vh + 1vmin);
    padding: 3%;
    cursor:default;
}

@media screen and (min-width: 426px) {
    .profile {
        padding: 15vh 5vw;
    }
    
    .picture {
        /* width: 70%; */
    }
}

@media screen and (min-width: 768px) {
    .picture {
        /* width: calc(70% - 2vw); */
    }
}

@media screen and (min-width: 1200px) {
    .picture {
        /* width:90%; */
    }
}

.picture-owner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}