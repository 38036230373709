.project-container {
    max-width: 100%;
    padding: 3vh 2vw;
    color: #333;
    background-color: #f7f9fc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    margin: 5vh 3vw 0 3vw;
}

.project-page {
    background-color: var(--bg-clear);
    padding: 15vh 0 10vh 0;
}

.project-header {
    text-align: center;
    margin-bottom: 20px;
}

.project-header h1 {
    font-size: 2.4em;
    color: var(--theme-text);
    margin: 0;
    line-height: 1.2;
}

.project-header p {
    font-size: 1.2em;
    margin-top: 10px;
    color: #666;
}

.project-description {
    margin: 20px 0;
    line-height: 1.6;
    text-align: justify;
    color: #444;
}

.project-content {
    margin: 20px 0;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    text-align: justify;
}

.project-content-title {
    font-size: 1.6em;
    color: var(--theme-lighter-text);
    margin-bottom: 5px;
}

.project-content-desc {
    margin-bottom: 10px;
    color: #555;
}

/* Compétences */
.project-competences {
    margin-top: 20px;
}

.project-competence-item {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.project-competence-item:last-child {
    border-bottom: none;
}

.project-competence-title {
    font-weight: bold;
    color: var(--plain-text);
}

.project-competence-desc {
    margin-top: 5px;
    color: #666;
}

/* Lignes de séparation */
.project-separator {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #e0e0e0;
}

.project-back {
    margin-top: 10vh;
    text-align: center;
}

@media screen and (min-width: 425px) {
    .project-container {
        max-width: 85%;
        margin: 5vh auto 0 auto;
        padding: 5vh 2vw;
    }
}